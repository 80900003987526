import React from 'react'
import Layout from '../components/layout'
import { Link, useStaticQuery, graphql } from "gatsby"

import Head from '../components/head'

import bizeyaz from "../images/bizeyaz.svg"
import { RiArrowRightSLine } from 'react-icons/ri';

import '../styles/grid.scss'
import '../styles/index.scss'

const ContactPage = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata{
          email,phone
      }
   }
    }
  `)

  return(
    <Layout>
      <Head title="İletişim" description="Altın günü modeli ile para biriktirme uygulaması Çember ile iletişime geç. Formu doldurarak bizimle iletişime geçebilirsin. En kısa sürede geri döneceğiz." />
      <div className="row iletisim">
        <div className="container">
          <div className="col-6">
            <h1>Bize Yazın. <span role="img" aria-label="Emoji">✍🏻</span></h1>
            <p>Formu doldurarak bizimle iletişime geçebilirsin. En kısa sürede geri döneceğiz. </p>
            <div className="col-12">
              <form action="/success" name="iletisim"  method="post" netlify-honeypot="bot-field" data-netlify="true" >
                <input type="hidden" name="form-name" value="iletisim" />
                <input required type="text" name="isim" placeholder="Ad Soyad *" />
                <input required type="email" name="eposta" placeholder="E-posta Adresi *" />
                <input required type="text" name="telefon" placeholder="Telefon *" />
                <textarea required rows="5" name="mesaj" placeholder="Mesajın">       
                </textarea>
                <button type="submit" >Gönder</button>
              </form>


              </div>
          </div>
          <div className="col-6">
          <img src={bizeyaz} alt="Bize Yaz" />

          <p>Mail atmak istersen:
          <a href="mailto:merhaba@tio.ist" >{data.site.siteMetadata.email}  </a>
          </p>

          <p>Telefon:
          <Link to={data.site.siteMetadata.phone}> {data.site.siteMetadata.phone}  </Link>
          </p>
          <h5><Link to="/help"><RiArrowRightSLine size="30" style={{ 'color': "#F93666" }}/> Soruların mı var?  <span role="img" aria-label="Emoji"> 🤔</span></Link></h5>
          

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage